import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Bar, Line } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import actionBreadcrumbs from 'redux/store/breadcrumbs/action';
import { months, numbers, transparentize, CHART_COLORS } from 'utils/chart';
import routes from 'operations/routing/routes';
import { Link } from 'react-router-dom';
import api from 'operations/network/api';
import axiosClient from 'lib/axiosConfig';
import useQueryString from 'utils/useQueryString';
import { useQueryCustom } from 'utils/useQueryCustom';
import isoToDate from 'utils/isoToDate';
import { format } from 'date-fns';
import FinanceDashboard from './components/financialDashborad';
import SubscriptionDashboard from './components/subscriptionDashboard';
import Statistics from './components/statistics';
import WelcomeBack from './components/welcomeBack';

// import "react-datepicker/dist/react-datepicker.css";

const Dashboard = () => {
    console.log('dashboard --------------------------------------');
    const dispatch = useDispatch();

    const QueryString = useQueryString({ sortBy: 'createdAt:desc' });
    const { page, limit } = QueryString;
    const getting = async () =>
        await axiosClient().get(api.dashboard, {
            params: { sortBy: 'createdAt:desc' },
        });
    const getSystemChatStatistics = async () => await axiosClient().get(api.systemChatStatistics);
    // const data = {}

    // query Get

    const {
        data: { data = {} } = {},
        error,
        isError,
        isLoading,
        refetch,
    } = useQueryCustom({
        name: `dashboard_get`,
        url: getting,
    });
    const { data: { data: { data: systemChatStatistics } = {} } = {} } = useQueryCustom({
        name: `system_chat_statistics_get`,
        url: getSystemChatStatistics,
    });
    console.log({ systemChatStatistics });

    // Chart data
    const DATA_COUNT = 7;
    const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
    const labels = months({ count: 7 });
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Unfilled',
                fill: false,
                backgroundColor: CHART_COLORS.blue,
                borderColor: CHART_COLORS.blue,
                data: numbers(NUMBER_CFG),
            },
            {
                label: 'Dashed',
                fill: false,
                backgroundColor: CHART_COLORS.green,
                borderColor: CHART_COLORS.green,
                borderDash: [5, 5],
                data: numbers(NUMBER_CFG),
            },
            {
                label: 'Filled',
                backgroundColor: CHART_COLORS.red,
                borderColor: CHART_COLORS.red,
                data: numbers(NUMBER_CFG),
                fill: false,
            },
        ],
    };

    const chartOptions = {
        type: 'line',
        data: data,
        options: {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: 'Chart.js Line Chart',
                },
            },
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Month',
                    },
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Value',
                    },
                },
            },
        },
    };

    const chatData = [
        {
            title: 'total',
            color: 'success',
            badge: 'success',
            icon: 'mdi mdi-account-plus',
            link: routes.user.base,
            count: data?.data?.agents?.total || 0,
        },
        {
            title: 'unread',
            color: 'info',
            badge: 'success',
            icon: 'mdi mdi-account-check',
            link: routes.transaction.base,
            count: data?.data?.agents?.online || 0,
        },
        {
            title: 'replied',
            color: 'danger',
            badge: 'danger',
            icon: 'mdi mdi-account-minus',
            link: routes.activity.base,
            count: (data?.data?.agents?.total || 0) - (data?.data?.agents?.online || 0) || 0,
        },
    ];
    const messagesData = [
        {
            title: 'Messages',
            color: 'info',
            badge: 'success',
            icon: 'mdi mdi-message-bulleted',
            count: systemChatStatistics?.messageCount || 0,
        },
        {
            title: 'Messages Coin Used',
            color: 'danger',
            badge: 'danger',
            icon: 'mdi mdi-circle-multiple',
            count: systemChatStatistics?.messagesCoinUsed || 0,
        },
    ];
    const giftsData = [
        {
            title: 'Gifts',
            color: 'success',
            badge: 'success',
            icon: 'mdi mdi-gift',
            count: systemChatStatistics?.giftCount || 0,
        },
        {
            title: 'Gifts Coin Used',
            color: 'danger',
            badge: 'danger',
            icon: 'mdi mdi-circle-multiple',
            count: systemChatStatistics?.giftsCoinUsed || 0,
        },
    ];

    // breadcrumbs
    const breadcrumbs = [{ title: 'Dashboard' }];
    // EFFECTS
    useEffect(() => {
        dispatch(actionBreadcrumbs.set(breadcrumbs));
    }, []);

    console.log({ data }, 'dashboard data');

    return (
        <div>
            <div className="row mb-5">
                <div className="col-md-6">
                    <Statistics data={giftsData} title={'Gifts'} />
                </div>
                <div className="col-md-6">
                    <Statistics data={messagesData} title={'Messages'} />
                </div>
            </div>
            <div className="row mb-5">
                {/* <div className="col-md-3"><WelcomeBack /></div> */}
                <div className="col-md-12">
                    <Statistics data={chatData} title={'Users'} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">chats</h4>
                            <Line data={chartData} options={chartOptions} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Dashboard;
